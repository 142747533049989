
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        












































































.time {
  background: $gray-200;

  .is-active & {
    background: $primary;
    color: $white;
  }
}
